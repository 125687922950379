import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import EastIcon from '@mui/icons-material/East';
import Grid from '@mui/material/Grid';
import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ButtonDropdownMenu from '@/components/core/ButtonDropdownMenu';
import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';
import SitesDetailActions from '@/components/SitesDetailActions';
import SitesDetailDeleteModal from '@/components/SitesDetailDeleteModal';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LandingSite20211012Site } from '@clients/landing-site';

const defaultProps = {
  isDeleteModalOpen: false,
  loading: true,
  setIsManageMenuOpen: false,
};

type SitesDetailViewProps = {
  error: any;
  isDeleteModalOpen: boolean;
  isManageMenuOpen: boolean;
  loading: boolean;
  organizationId: string | number;
  setIsDeleteModalOpen: () => void;
  setIsManageMenuOpen: () => void;
  site: LandingSite20211012Site;
  siteId: string | number;
} & typeof defaultProps;

export function SitesDetailView({
  error,
  isDeleteModalOpen,
  isManageMenuOpen,
  loading,
  organizationId,
  setIsDeleteModalOpen,
  setIsManageMenuOpen,
  site,
  siteId,
}: SitesDetailViewProps) {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={4}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}/sites`}
            underline="hover"
          >
            {t(`sites.sites`)}
          </Link>
          <Typography color="text.primary" sx={{ minWidth: `50px` }}>
            {loading ? <Skeleton /> : site?.name}
          </Typography>
        </Breadcrumbs>
        {!error && (
          <Stack
            direction={{ xs: `row`, sm: `row` }}
            justifyContent="space-between"
          >
            <Box>
              <Typography
                color="inherit"
                component="div"
                variant="h1"
                sx={{ minWidth: `400px`, mb: { xs: 1, sm: 0 } }}
              >
                {loading ? <Skeleton /> : site?.name}
              </Typography>
              <Stack
                direction="row"
                spacing={1.5}
                sx={{ my: 1.5 }}
                alignItems="center"
              >
                {loading ? (
                  <>
                    <Skeleton width="50px" />
                    <Skeleton width="200px" />
                  </>
                ) : (
                  <>
                    <Chip
                      color={site?.state === `ONLINE` ? `success` : `error`}
                      label={site?.state}
                      size="small"
                    />
                    <Typography color="text.secondary">
                      {t(`sites.created-at`)}
                      &nbsp;
                      {new Intl.DateTimeFormat(`en-US`, {
                        dateStyle: `medium`,
                        timeStyle: `short`,
                      }).format(site?.createdAt)}
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>
            <Box>
              <ButtonDropdownMenu
                aria-label={t(`sites.manage`)}
                disabled={loading}
                isOpen={isManageMenuOpen}
                label={t(`sites.manage`)}
                onClick={() => setIsManageMenuOpen(true)}
                onClose={() => setIsManageMenuOpen(false)}
              >
                <SitesDetailActions
                  isOpen={isManageMenuOpen}
                  onClose={() => setIsManageMenuOpen(false)}
                  organizationId={organizationId}
                  siteId={site?.id}
                />
              </ButtonDropdownMenu>
            </Box>
          </Stack>
        )}
      </Box>
      <Box>
        {loading ? (
          <PageLoading></PageLoading>
        ) : error ? (
          <PageError>{error.message}</PageError>
        ) : site ? (
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <Paper elevation={0} component={Card}>
                <CardHeader
                  title={t(`sites.detail.details`)}
                  sx={{ px: 0, py: 1.5 }}
                />
                <CardContent sx={{ p: 0 }}>
                  <List>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemText
                        sx={{ maxWidth: 2 / 6 }}
                        primaryTypographyProps={{ sx: { color: `grey.700` } }}
                        primary={t(`sites.detail.key-id`)}
                      />
                      <ListItemText primary={siteId} />
                    </ListItem>
                    {site?.domain?.name && (
                      <ListItem sx={{ px: 0 }}>
                        <ListItemText
                          sx={{ maxWidth: 2 / 6 }}
                          primaryTypographyProps={{ sx: { color: `grey.700` } }}
                          primary={t(`sites.detail.key-site`)}
                        />
                        <ListItemText
                          primary={
                            <Button
                              component={Link}
                              endIcon={<LaunchIcon />}
                              href={`https://${site?.domain?.name}`}
                              size="small"
                              target="_blank"
                            >
                              {site?.domain?.name}
                            </Button>
                          }
                        />
                      </ListItem>
                    )}
                    {site?.domain?.id && (
                      <ListItem sx={{ px: 0 }}>
                        <ListItemText
                          sx={{ maxWidth: 2 / 6 }}
                          primaryTypographyProps={{ sx: { color: `grey.700` } }}
                          primary={t(`sites.detail.key-domain`)}
                        />
                        <ListItemText
                          primary={
                            <Button
                              component={GatsbyLink}
                              endIcon={<EastIcon />}
                              to={`/organizations/${organizationId}/domains/${site?.domain?.id}`}
                              size="small"
                            >
                              {t(`sites.view-domain`)}
                            </Button>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </CardContent>
              </Paper>

              <Paper elevation={0} component={Card}>
                <CardHeader
                  title={t(`sites.detail.settings`)}
                  sx={{ px: 0, py: 1.5 }}
                />
                <CardContent sx={{ px: 0, py: 2 }}>
                  <Paper elevation={0} sx={{ mb: 2 }} variant="outlined">
                    <Box p={3}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Box>
                          <Typography sx={{ fontWeight: `500` }}>
                            {t(`sites.detail.settings.edit`)}
                          </Typography>
                          <Typography sx={{ color: `grey.A700` }}>
                            {t(`sites.detail.settings.change-and-publish`)}
                          </Typography>
                        </Box>
                        <Button
                          color="secondary"
                          disableRipple
                          variant="contained"
                        >
                          {t(`sites.actions.edit-site`)}
                        </Button>
                      </Stack>
                    </Box>
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{ borderColor: `red.A700` }}
                    variant="outlined"
                  >
                    <Box p={3}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Box>
                          <Typography sx={{ fontWeight: `500` }}>
                            {t(`sites.detail.settings.delete`)}
                          </Typography>
                          <Typography sx={{ color: `grey.A700` }}>
                            {t(`sites.detail.settings.delete-repercussions`)}
                          </Typography>
                        </Box>
                        <Button
                          color="error"
                          disableRipple
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                          }}
                          variant="contained"
                        >
                          {t(`sites.actions.delete-site`)}
                        </Button>
                        <SitesDetailDeleteModal
                          isOpen={isDeleteModalOpen}
                          next={`/organizations/${organizationId}/sites`}
                          onClose={() => {
                            setIsDeleteModalOpen(false);
                          }}
                          organizationId={organizationId}
                          siteId={siteId}
                        />
                      </Stack>
                    </Box>
                  </Paper>
                </CardContent>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

SitesDetailView.defaultProps = defaultProps;

export default SitesDetailView;
