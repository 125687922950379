import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import SitesDetail from '@/components/SitesDetail';

export function SitesByIdIndex({
  organizationId,
  siteId,
}: {
  organizationId: string;
  siteId: string;
}) {
  return (
    <AppFrame organizationId={organizationId}>
      <SitesDetail organizationId={organizationId} siteId={siteId} />
    </AppFrame>
  );
}

export default withAuthenticationRequired(SitesByIdIndex);
