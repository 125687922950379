import { useState } from 'react';
import { useApi } from '@/hooks/use-api';

import api from '@/utilities/api';
import SitesDetailView from '@/components/SitesDetail/SitesDetailView';

const defaultProps = {};

type SitesDetailContainerProps = {
  organizationId: string | number;
  siteId: string | number;
} & typeof defaultProps;

export function SitesDetailContainer({
  organizationId,
  siteId,
}: SitesDetailContainerProps) {
  const [getSiteParams] = useState([organizationId, siteId]);
  const [getSiteOptions] = useState({
    isDisabled: false,
  });
  const {
    data: getSiteData,
    error: getSiteError,
    loading: getSiteLoading,
  } = useApi(api.sites.get.bind(api.sites), getSiteParams, getSiteOptions);

  const [isManageMenuOpen, setIsManageMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <SitesDetailView
      error={getSiteError}
      isDeleteModalOpen={isDeleteModalOpen}
      isManageMenuOpen={isManageMenuOpen}
      loading={getSiteLoading}
      organizationId={organizationId}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
      setIsManageMenuOpen={setIsManageMenuOpen}
      site={getSiteData?.site}
      siteId={siteId}
    />
  );
}

SitesDetailContainer.defaultProps = defaultProps;

export default SitesDetailContainer;
